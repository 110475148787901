import { createListenerMiddleware } from "@reduxjs/toolkit";
import {
  fetchCommonInfo,
  fetchEfficaciaProgetto,
  fetchEfficaciaProgettoSuccess,
  fetchEfficaciaProgettoFailed,
  fetchTipologiaProgetto,
  fetchTipologiaProgettoSuccess,
  fetchTipologiaProgettoFailed,
  fetchTipologiaIstituto,
  fetchTipologiaIstitutoSuccess,
  fetchTipologiaIstitutoFailed,
  fetchTipologiaStudentiBes,
  fetchTipologiaStudentiBesSuccess,
  fetchTipologiaStudentiBesFailed,
  fetchStatoPubblicazione,
  fetchStatoPubblicazioneSuccess,
  fetchStatoPubblicazioneFailed,
  fetchProvince,
  fetchProvinceSuccess,
  fetchProvinceFailed,
  fetchIstitutiScolastici,
  fetchIstitutiScolasticiSuccess,
  fetchIstitutiScolasticiFailed,
  fetchTipologiaDocumentazione,
  fetchTipologiaDocumentazioneSuccess,
  fetchTipologiaDocumentazioneFailed,
  fetchContestoAccompagnamento,
  fetchContestoAccompagnamentoSuccess,
  fetchContestoAccompagnamentoFailed,
  fetchIncarichi,
  fetchIncarichiSuccess,
  fetchIncarichiFailed,
  fetchIndirizzoQualificaIstituto,
  fetchIndirizzoQualificaIstitutoSuccess,
  fetchIndirizzoQualificaIstitutoFailed,
  fetchAttivitaCurricolari,
  fetchAttivitaCurricolariSuccess,
  fetchAttivitaCurricolariFailed,
  fetchAttivitaClil,
  fetchAttivitaClilSuccess,
  fetchAttivitaClilFailed,
  fetchTipologiaBeneficiarioProgetto,
  fetchTipologiaBeneficiarioProgettoSuccess,
  fetchTipologiaBeneficiarioProgettoFailed,
  fetchTempoRealizzazioneProgetto,
  fetchTempoRealizzazioneProgettoSuccess,
  fetchTempoRealizzazioneProgettoFailed,
  fetchTematicaProgetto,
  fetchTematicaProgettoSuccess,
  fetchTematicaProgettoFailed,
  fetchOrdineScuola,
  fetchOrdineScuolaSuccess,
  fetchOrdineScuolaFailed,
  fetchFondiContestoProgetto,
  fetchFondiContestoProgettoSuccess,
  fetchFondiContestoProgettoFailed,
  fetchComposizioneGruppoStudenti,
  fetchComposizioneGruppoStudentiSuccess,
  fetchComposizioneGruppoStudentiFailed,
  fetchAttivitaDigitale,
  fetchAttivitaDigitaleSuccess,
  fetchAttivitaDigitaleFailed,
  fetchAreaCoinvolta,
  fetchAreaCoinvoltaSuccess,
  fetchAreaCoinvoltaFailed,
  fetchAnnoScolasticoRealizzazione,
  fetchAnnoScolasticoRealizzazioneSuccess,
  fetchAnnoScolasticoRealizzazioneFailed,
  fetchTitolo,
  fetchTitoloSuccess,
  fetchTitoloFailed,
  fetchAzioniDiffusioneDisseminazione,
  fetchAzioniDiffusioneDisseminazioneSuccess,
  fetchAzioniDiffusioneDisseminazioneFailed,
  fetchAttivitaValutazioneEfficaciaProgetto,
  fetchAttivitaValutazioneEfficaciaProgettoSuccess,
  fetchAttivitaValutazioneEfficaciaProgettoFailed,
  preparaListaIstituti,
  fetchReferenti,
  fetchReferentiSuccess,
  fetchReferentiFailed,
  fetchCoResponsabili,
  fetchCoResponsabiliSuccess,
  fetchCoResponsabiliFailed,
  fetchAttivitaCurricolariRicerca,
  fetchAttivitaCurricolariRicercaSuccess,
  fetchCoReferentiSuccess,
  fetchCoReferenti,
  fetchCoReferentiFailed,
} from "./index";
import { setNotification, notificationCodes } from "../notification";
import { createRequest } from "../../utility";
import {
  VALUTAZIONE_EFFICACIA_PROGETTO_API,
  TIPOLOGIA_PROGETTO_API,
  TIPOLOGIA_ISTITUTO_API,
  TIPOLOGIA_STUDENTI_BES_API,
  STATO_PUBBLICAZIONE_API,
  PROVINCE_API,
  ISTITUTI_SCOLASTICI_API,
  TIPOLOGIA_DOCUMENTAZIONE_API,
  CONTESTO_ACCOMPAGNAMENTO_API,
  INCARICHI_API,
  INDIRIZZO_QUALIFICA_ISTITUTO_API,
  ATTIVITA_CURRICOLARI_API,
  ATTIVITA_RICERCA_CURRICOLARI_API,
  ATTIVITA_CLIL_API,
  TIPOLOGIA_BENEFICIARIO_PROGETTO_API,
  TEMPO_REALIZZAZIONE_PROGETTO_API,
  TEMATICA_PROGETTO_API,
  ORDINE_SCUOLA_API,
  FONDI_CONTESTO_PROGETTO_API,
  COMPOSIZIONE_GRUPPO_STUDENTI_API,
  ATTIVITA_DIGITALE_API,
  AREA_COINVOLTA_API,
  ANNO_SCOLASTICO_REALIZZAZIONE_API,
  TITOLO_API,
  AZIONI_DIFFUSIONE_DISSEMINAZIONE_API,
  ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_API,
  REFERENTI_API,
  CO_RESPONSABILE_API,
  CO_REFERENTI_API,
} from "../../apiUrl";
import { labels } from "../../labels";

const {
  SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE,
  SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION,
  SAGAS_TIPOLOGIA_PROGETTO_TITLE,
  SAGAS_TIPOLOGIA_PROGETTO_DESCRIPTION,
  SAGAS_TIPOLOGIA_ISTITUTO_TITLE,
  SAGAS_TIPOLOGIA_ISTITUTO_DESCRIPTION,
  SAGAS_TIPOLOGIA_STUDENTI_BES_TITLE,
  SAGAS_TIPOLOGIA_STUDENTI_BES_DESCRIPTION,
  SAGAS_STATO_PUBBLICAZIONE_TITLE,
  SAGAS_STATO_PUBBLICAZIONE_DESCRIPTION,
  SAGAS_PROVINCE_TITLE,
  SAGAS_PROVINCE_DESCRIPTION,
  SAGAS_ISTITUTI_SCOLASTICI_TITLE,
  SAGAS_ISTITUTI_SCOLASTICI_DESCRIPTION,
  SAGAS_TIPOLOGIA_DOCUMENTAZIONE_TITLE,
  SAGAS_TIPOLOGIA_DOCUMENTAZIONE_DESCRIPTION,
  SAGAS_CONTESTO_ACCOMPAGNAMENTO_TITLE,
  SAGAS_CONTESTO_ACCOMPAGNAMENTO_DESCRIPTION,
  SAGAS_INCARICHI_TITLE,
  SAGAS_INCARICHI_DESCRIPTION,
  SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_TITLE,
  SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_DESCRIPTION,
  SAGAS_ATTIVITA_CURRICOLARI_TITLE,
  SAGAS_ATTIVITA_CURRICOLARI_DESCRIPTION,
  SAGAS_ATTIVITA_CURRICOLARI_RICERCA_TITLE,
  SAGAS_ATTIVITA_CURRICOLARI_RICERCA_DESCRIPTION,
  SAGAS_ATTIVITA_CLIL_TITLE,
  SAGAS_ATTIVITA_CLIL_DESCRIPTION,
  SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_TITLE,
  SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_DESCRIPTION,
  SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_TITLE,
  SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_DESCRIPTION,
  SAGAS_TEMATICA_PROGETTO_TITLE,
  SAGAS_TEMATICA_PROGETTO_DESCRIPTION,
  SAGAS_ORDINE_SCUOLA_TITLE,
  SAGAS_ORDINE_SCUOLA_DESCRIPTION,
  SAGAS_FONDI_CONTESTO_PROGETTO_TITLE,
  SAGAS_FONDI_CONTESTO_PROGETTO_DESCRIPTION,
  SAGAS_ATTIVITA_DIGITALE_TITLE,
  SAGAS_ATTIVITA_DIGITALE_DESCRIPTION,
  SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_TITLE,
  SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_DESCRIPTION,
  SAGAS_AREA_COINVOLTA_TITLE,
  SAGAS_AREA_COINVOLTA_DESCRIPTION,
  SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_TITLE,
  SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_DESCRIPTION,
  SAGAS_TITOLO_TITLE,
  SAGAS_TITOLO_DESCRIPTION,
  SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_TITLE,
  SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_DESCRIPTION,
  SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE,
  SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION,
  SAGAS_REFERENTI_DESCRIPTION,
  SAGAS_REFERENTI_TITLE,
  SAGAS_CO_RESPONSABILE_TITLE,
  SAGAS_CO_RESPONSABILE_DESCRIPTION,
  SAGAS_CO_REFERENTI_DESCRIPTION,
  SAGAS_CO_REFERENTI_TITLE,
} = labels;

const fetchEfficaciaProgettoWorker = (listenerApi) => {
  createRequest(VALUTAZIONE_EFFICACIA_PROGETTO_API.method, VALUTAZIONE_EFFICACIA_PROGETTO_API.baseUrl, VALUTAZIONE_EFFICACIA_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchEfficaciaProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE, description: SAGAS_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchEfficaciaProgettoFailed());
    });
};

const fetchTipologiaProgettoWorker = (listenerApi) => {
  createRequest(TIPOLOGIA_PROGETTO_API.method, TIPOLOGIA_PROGETTO_API.baseUrl, TIPOLOGIA_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTipologiaProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TIPOLOGIA_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TIPOLOGIA_PROGETTO_TITLE, description: SAGAS_TIPOLOGIA_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTipologiaProgettoFailed());
    });
};

const fetchTipologiaIstitutoWorker = (listenerApi) => {
  createRequest(TIPOLOGIA_ISTITUTO_API.method, TIPOLOGIA_ISTITUTO_API.baseUrl, TIPOLOGIA_ISTITUTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTipologiaIstitutoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TIPOLOGIA_ISTITUTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TIPOLOGIA_ISTITUTO_TITLE, description: SAGAS_TIPOLOGIA_ISTITUTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTipologiaIstitutoFailed());
    });
};

const fetchTipologiaStudentiBesWorker = (listenerApi) => {
  createRequest(TIPOLOGIA_STUDENTI_BES_API.method, TIPOLOGIA_STUDENTI_BES_API.baseUrl, TIPOLOGIA_STUDENTI_BES_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTipologiaStudentiBesSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TIPOLOGIA_STUDENTI_BES_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TIPOLOGIA_STUDENTI_BES_TITLE, description: SAGAS_TIPOLOGIA_STUDENTI_BES_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTipologiaStudentiBesFailed());
    });
};

const fetchStatoPubblicazioneWorker = (listenerApi) => {
  createRequest(STATO_PUBBLICAZIONE_API.method, STATO_PUBBLICAZIONE_API.baseUrl, STATO_PUBBLICAZIONE_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchStatoPubblicazioneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_STATO_PUBBLICAZIONE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_STATO_PUBBLICAZIONE_TITLE, description: SAGAS_STATO_PUBBLICAZIONE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchStatoPubblicazioneFailed());
    });
};

const fetchProvinceWorker = (listenerApi) => {
  createRequest(PROVINCE_API.method, PROVINCE_API.baseUrl, PROVINCE_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchProvinceSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_PROVINCE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_PROVINCE_TITLE, description: SAGAS_PROVINCE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchProvinceFailed());
    });
};

const fetchIstitutiScolasticiWorker = (listenerApi) => {
  createRequest(ISTITUTI_SCOLASTICI_API.method, ISTITUTI_SCOLASTICI_API.baseUrl, ISTITUTI_SCOLASTICI_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchIstitutiScolasticiSuccess(preparaListaIstituti(response.data)));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ISTITUTI_SCOLASTICI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ISTITUTI_SCOLASTICI_TITLE, description: SAGAS_ISTITUTI_SCOLASTICI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchIstitutiScolasticiFailed());
    });
};

const fetchTipologiaDocumentazioneWorker = (listenerApi) => {
  createRequest(TIPOLOGIA_DOCUMENTAZIONE_API.method, TIPOLOGIA_DOCUMENTAZIONE_API.baseUrl, TIPOLOGIA_DOCUMENTAZIONE_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTipologiaDocumentazioneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TIPOLOGIA_DOCUMENTAZIONE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TIPOLOGIA_DOCUMENTAZIONE_TITLE, description: SAGAS_TIPOLOGIA_DOCUMENTAZIONE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTipologiaDocumentazioneFailed());
    });
};

const fetchContestoAccompagnamentoWorker = (listenerApi) => {
  createRequest(CONTESTO_ACCOMPAGNAMENTO_API.method, CONTESTO_ACCOMPAGNAMENTO_API.baseUrl, CONTESTO_ACCOMPAGNAMENTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchContestoAccompagnamentoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_CONTESTO_ACCOMPAGNAMENTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_CONTESTO_ACCOMPAGNAMENTO_TITLE, description: SAGAS_CONTESTO_ACCOMPAGNAMENTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchContestoAccompagnamentoFailed());
    });
};

const fetchIncarichiWorker = (listenerApi) => {
  createRequest(INCARICHI_API.method, INCARICHI_API.baseUrl, INCARICHI_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchIncarichiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_INCARICHI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_INCARICHI_TITLE, description: SAGAS_INCARICHI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchIncarichiFailed());
    });
};

const fetchIndirizzoQualificaIstitutoWorker = (listenerApi) => {
  createRequest(INDIRIZZO_QUALIFICA_ISTITUTO_API.method, INDIRIZZO_QUALIFICA_ISTITUTO_API.baseUrl, INDIRIZZO_QUALIFICA_ISTITUTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchIndirizzoQualificaIstitutoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_TITLE, description: SAGAS_INDIRIZZO_QUALIFICA_ISTITUTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchIndirizzoQualificaIstitutoFailed());
    });
};

const fetchAttivitaCurricolariWorker = (listenerApi) => {
  createRequest(ATTIVITA_CURRICOLARI_API.method, ATTIVITA_CURRICOLARI_API.baseUrl, ATTIVITA_CURRICOLARI_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaCurricolariSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_CURRICOLARI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_CURRICOLARI_TITLE, description: SAGAS_ATTIVITA_CURRICOLARI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaCurricolariFailed());
    });
};

const fetchAttivitaCurricolariRicercaWorker = (listenerApi) => {
  createRequest(ATTIVITA_RICERCA_CURRICOLARI_API.method, ATTIVITA_RICERCA_CURRICOLARI_API.baseUrl, ATTIVITA_RICERCA_CURRICOLARI_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaCurricolariRicercaSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_CURRICOLARI_RICERCA_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_CURRICOLARI_RICERCA_TITLE, description: SAGAS_ATTIVITA_CURRICOLARI_RICERCA_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaCurricolariFailed());
    });
};

const fetchAttivitaClilWorker = (listenerApi) => {
  createRequest(ATTIVITA_CLIL_API.method, ATTIVITA_CLIL_API.baseUrl, ATTIVITA_CLIL_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaClilSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_CLIL_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_CLIL_TITLE, description: SAGAS_ATTIVITA_CLIL_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaClilFailed());
    });
};

const fetchTipologiaBeneficiarioProgettoWorker = (listenerApi) => {
  createRequest(TIPOLOGIA_BENEFICIARIO_PROGETTO_API.method, TIPOLOGIA_BENEFICIARIO_PROGETTO_API.baseUrl, TIPOLOGIA_BENEFICIARIO_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTipologiaBeneficiarioProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_TITLE, description: SAGAS_TIPOLOGIA_BENEFICIARIO_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTipologiaBeneficiarioProgettoFailed());
    });
};

const fetchTempoRealizzazioneProgettoWorker = (listenerApi) => {
  createRequest(TEMPO_REALIZZAZIONE_PROGETTO_API.method, TEMPO_REALIZZAZIONE_PROGETTO_API.baseUrl, TEMPO_REALIZZAZIONE_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTempoRealizzazioneProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_TITLE, description: SAGAS_TEMPO_REALIZZAZIONE_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTempoRealizzazioneProgettoFailed());
    });
};

const fetchTematicaProgettoWorker = (listenerApi) => {
  createRequest(TEMATICA_PROGETTO_API.method, TEMATICA_PROGETTO_API.baseUrl, TEMATICA_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTematicaProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TEMATICA_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TEMATICA_PROGETTO_TITLE, description: SAGAS_TEMATICA_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTematicaProgettoFailed());
    });
};

const fetchOrdineScuolaWorker = (listenerApi) => {
  createRequest(ORDINE_SCUOLA_API.method, ORDINE_SCUOLA_API.baseUrl, ORDINE_SCUOLA_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchOrdineScuolaSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ORDINE_SCUOLA_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ORDINE_SCUOLA_TITLE, description: SAGAS_ORDINE_SCUOLA_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchOrdineScuolaFailed());
    });
};

const fetchFondiContestoProgettoWorker = (listenerApi) => {
  createRequest(FONDI_CONTESTO_PROGETTO_API.method, FONDI_CONTESTO_PROGETTO_API.baseUrl, FONDI_CONTESTO_PROGETTO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchFondiContestoProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_FONDI_CONTESTO_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_FONDI_CONTESTO_PROGETTO_TITLE, description: SAGAS_FONDI_CONTESTO_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchFondiContestoProgettoFailed());
    });
};

const fetchAttivitaDigitaleWorker = (listenerApi) => {
  createRequest(ATTIVITA_DIGITALE_API.method, ATTIVITA_DIGITALE_API.baseUrl, ATTIVITA_DIGITALE_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaDigitaleSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_DIGITALE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_DIGITALE_TITLE, description: SAGAS_ATTIVITA_DIGITALE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaDigitaleFailed());
    });
};

const fetchComposizioneGruppoStudentiWorker = (listenerApi) => {
  createRequest(COMPOSIZIONE_GRUPPO_STUDENTI_API.method, COMPOSIZIONE_GRUPPO_STUDENTI_API.baseUrl, COMPOSIZIONE_GRUPPO_STUDENTI_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchComposizioneGruppoStudentiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_TITLE, description: SAGAS_COMPOSIZIONE_GRUPPO_STUDENTI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchComposizioneGruppoStudentiFailed());
    });
};

const fetchAreaCoinvoltaWorker = (listenerApi) => {
  createRequest(AREA_COINVOLTA_API.method, AREA_COINVOLTA_API.baseUrl, AREA_COINVOLTA_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAreaCoinvoltaSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_AREA_COINVOLTA_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_AREA_COINVOLTA_TITLE, description: SAGAS_AREA_COINVOLTA_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAreaCoinvoltaFailed());
    });
};

const fetchAnnoScolasticoRealizzazioneWorker = (listenerApi) => {
  createRequest(ANNO_SCOLASTICO_REALIZZAZIONE_API.method, ANNO_SCOLASTICO_REALIZZAZIONE_API.baseUrl, ANNO_SCOLASTICO_REALIZZAZIONE_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAnnoScolasticoRealizzazioneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_TITLE, description: SAGAS_ANNO_SCOLASTICO_REALIZZAZIONE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAnnoScolasticoRealizzazioneFailed());
    });
};

const fetchTitoloWorker = (listenerApi) => {
  createRequest(TITOLO_API.method, TITOLO_API.baseUrl, TITOLO_API.apiUrl)
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchTitoloSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_TITOLO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_TITOLO_TITLE, description: SAGAS_TITOLO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchTitoloFailed());
    });
};

const fetchAzioniDiffusioneDisseminazioneWorker = (listenerApi) => {
  createRequest(AZIONI_DIFFUSIONE_DISSEMINAZIONE_API.method, AZIONI_DIFFUSIONE_DISSEMINAZIONE_API.baseUrl, AZIONI_DIFFUSIONE_DISSEMINAZIONE_API.apiUrl) //prettier-ignore
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAzioniDiffusioneDisseminazioneSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_TITLE, description: SAGAS_AZIONI_DIFFUSIONE_DISSEMINAZIONE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAzioniDiffusioneDisseminazioneFailed());
    });
};

const fetchAttivitaValutazioneEfficaciaProgettoWorker = (listenerApi) => {
  createRequest(ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_API.method, ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_API.baseUrl, ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_API.apiUrl) //prettier-ignore
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchAttivitaValutazioneEfficaciaProgettoSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_TITLE, description: SAGAS_ATTIVITA_VALUTAZIONE_EFFICACIA_PROGETTO_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchAttivitaValutazioneEfficaciaProgettoFailed());
    });
};

const fetchReferentiWorker = (listenerApi) => {
  createRequest(REFERENTI_API.method, REFERENTI_API.baseUrl, REFERENTI_API.apiUrl) //prettier-ignore
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchReferentiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_REFERENTI_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_REFERENTI_TITLE, description: SAGAS_REFERENTI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchReferentiFailed());
    });
};

const fetchCoReferentiWorker = (listenerApi) => {
  createRequest(CO_REFERENTI_API.method, CO_REFERENTI_API.baseUrl, CO_REFERENTI_API.apiUrl) //prettier-ignore
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchCoReferentiSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_CO_REFERENTI_TITLE, description: SAGAS_CO_REFERENTI_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchCoReferentiFailed());
    });
};

const fetchCoResponsabiliWorker = (listenerApi) => {
  createRequest(CO_RESPONSABILE_API.method, CO_RESPONSABILE_API.baseUrl, CO_RESPONSABILE_API.apiUrl) //prettier-ignore
    .then((response) => {
      if (response.status === 200) listenerApi.dispatch(fetchCoResponsabiliSuccess(response.data));
      else throw response.message;
    })
    .catch((e) => {
      console.error(SAGAS_CO_RESPONSABILE_TITLE, ":", e);
      listenerApi.dispatch(setNotification({ tipo: notificationCodes.ERROR, message: SAGAS_CO_RESPONSABILE_TITLE, description: SAGAS_CO_RESPONSABILE_DESCRIPTION })); //prettier-ignore
      listenerApi.dispatch(fetchCoResponsabiliFailed());
    });
};

export const commonMiddleware = createListenerMiddleware();
commonMiddleware.startListening({
  actionCreator: fetchCommonInfo,
  effect: async (action, listenerApi) => {
    const {
      efficaciaProgetto,
      tipologieProgetto,
      tipologieIstituto,
      tipologieStudentiBes,
      statiPubblicazione,
      province,
      istitutiScolastici,
      tipologieDocumentazione,
      contestiAccompagnamento,
      incarichi,
      indirizziQualificaIstituto,
      attivitaCurricolari,
      attivitaClil,
      tipologieBeneficiarioProgetto,
      tempoRealizzazioneProgetto,
      tematicheProgetto,
      ordiniScuola,
      fondiContestoProgetto,
      composizioneGruppoStudenti,
      attivitaDigitali,
      areeCoinvolte,
      anniScolasticiRealizzazione,
      titoli,
      azioniDiffusioneDisseminazione,
      attivitaValutazioneEfficaciaProgetto,
      referenti,
      coResponsabili,
      attivitaCurricolariRicerca,
      coReferenti,
    } = listenerApi.getState().common;

    if (efficaciaProgetto?.data == null) listenerApi.dispatch(fetchEfficaciaProgetto());
    if (tipologieProgetto?.data == null) listenerApi.dispatch(fetchTipologiaProgetto());
    if (tipologieIstituto?.data == null) listenerApi.dispatch(fetchTipologiaIstituto());
    if (tipologieStudentiBes?.data == null) listenerApi.dispatch(fetchTipologiaStudentiBes());
    if (statiPubblicazione?.data == null) listenerApi.dispatch(fetchStatoPubblicazione());
    if (province?.data == null) listenerApi.dispatch(fetchProvince());
    listenerApi.dispatch(fetchIstitutiScolastici()); //tolto controllo,chiamata ogni volta per variazione istituti
    if (tipologieDocumentazione?.data == null) listenerApi.dispatch(fetchTipologiaDocumentazione());
    if (contestiAccompagnamento?.data == null) listenerApi.dispatch(fetchContestoAccompagnamento());
    if (incarichi?.data == null) listenerApi.dispatch(fetchIncarichi());
    if (indirizziQualificaIstituto?.data == null) listenerApi.dispatch(fetchIndirizzoQualificaIstituto());
    if (attivitaCurricolari?.data == null) listenerApi.dispatch(fetchAttivitaCurricolari());
    if (attivitaCurricolariRicerca?.data == null) listenerApi.dispatch(fetchAttivitaCurricolariRicerca());
    if (attivitaClil?.data == null) listenerApi.dispatch(fetchAttivitaClil());
    if (tipologieBeneficiarioProgetto?.data == null) listenerApi.dispatch(fetchTipologiaBeneficiarioProgetto());
    if (tempoRealizzazioneProgetto?.data == null) listenerApi.dispatch(fetchTempoRealizzazioneProgetto());
    if (tematicheProgetto?.data == null) listenerApi.dispatch(fetchTematicaProgetto());
    if (ordiniScuola?.data == null) listenerApi.dispatch(fetchOrdineScuola());
    if (fondiContestoProgetto?.data == null) listenerApi.dispatch(fetchFondiContestoProgetto());
    if (composizioneGruppoStudenti?.data == null) listenerApi.dispatch(fetchComposizioneGruppoStudenti());
    if (attivitaDigitali?.data == null) listenerApi.dispatch(fetchAttivitaDigitale());
    if (areeCoinvolte?.data == null) listenerApi.dispatch(fetchAreaCoinvolta());
    if (anniScolasticiRealizzazione?.data == null) listenerApi.dispatch(fetchAnnoScolasticoRealizzazione());
    if (titoli?.data == null) listenerApi.dispatch(fetchTitolo());
    if (azioniDiffusioneDisseminazione?.data == null) listenerApi.dispatch(fetchAzioniDiffusioneDisseminazione());
    if (attivitaValutazioneEfficaciaProgetto?.data == null) listenerApi.dispatch(fetchAttivitaValutazioneEfficaciaProgetto());
    if (referenti?.data == null) listenerApi.dispatch(fetchReferenti());
    if (coResponsabili?.data == null) listenerApi.dispatch(fetchCoResponsabili());
    if (coReferenti?.data == null) listenerApi.dispatch(fetchCoReferenti());
  },
});

commonMiddleware.startListening({
  actionCreator: fetchEfficaciaProgetto,
  effect: async (action, listenerApi) => fetchEfficaciaProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTipologiaProgetto,
  effect: async (action, listenerApi) => fetchTipologiaProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTipologiaIstituto,
  effect: async (action, listenerApi) => fetchTipologiaIstitutoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTipologiaStudentiBes,
  effect: async (action, listenerApi) => fetchTipologiaStudentiBesWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchStatoPubblicazione,
  effect: async (action, listenerApi) => fetchStatoPubblicazioneWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchProvince,
  effect: async (action, listenerApi) => fetchProvinceWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchIstitutiScolastici,
  effect: async (action, listenerApi) => fetchIstitutiScolasticiWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTipologiaDocumentazione,
  effect: async (action, listenerApi) => fetchTipologiaDocumentazioneWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchContestoAccompagnamento,
  effect: async (action, listenerApi) => fetchContestoAccompagnamentoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchIncarichi,
  effect: async (action, listenerApi) => fetchIncarichiWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchIndirizzoQualificaIstituto,
  effect: async (action, listenerApi) => fetchIndirizzoQualificaIstitutoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAttivitaCurricolari,
  effect: async (action, listenerApi) => fetchAttivitaCurricolariWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAttivitaCurricolariRicerca,
  effect: async (action, listenerApi) => fetchAttivitaCurricolariRicercaWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAttivitaClil,
  effect: async (action, listenerApi) => fetchAttivitaClilWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTipologiaBeneficiarioProgetto,
  effect: async (action, listenerApi) => fetchTipologiaBeneficiarioProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTempoRealizzazioneProgetto,
  effect: async (action, listenerApi) => fetchTempoRealizzazioneProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTematicaProgetto,
  effect: async (action, listenerApi) => fetchTematicaProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchOrdineScuola,
  effect: async (action, listenerApi) => fetchOrdineScuolaWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchFondiContestoProgetto,
  effect: async (action, listenerApi) => fetchFondiContestoProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchComposizioneGruppoStudenti,
  effect: async (action, listenerApi) => fetchComposizioneGruppoStudentiWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAttivitaDigitale,
  effect: async (action, listenerApi) => fetchAttivitaDigitaleWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAreaCoinvolta,
  effect: async (action, listenerApi) => fetchAreaCoinvoltaWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAnnoScolasticoRealizzazione,
  effect: async (action, listenerApi) => fetchAnnoScolasticoRealizzazioneWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchTitolo,
  effect: async (action, listenerApi) => fetchTitoloWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAzioniDiffusioneDisseminazione,
  effect: async (action, listenerApi) => fetchAzioniDiffusioneDisseminazioneWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchAttivitaValutazioneEfficaciaProgetto,
  effect: async (action, listenerApi) => fetchAttivitaValutazioneEfficaciaProgettoWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchReferenti,
  effect: async (action, listenerApi) => fetchReferentiWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchCoReferenti,
  effect: async (action, listenerApi) => fetchCoReferentiWorker(listenerApi),
});

commonMiddleware.startListening({
  actionCreator: fetchCoResponsabili,
  effect: async (action, listenerApi) => fetchCoResponsabiliWorker(listenerApi),
});
